import React from "react"
import BannerHeader from "../components/bannerHeader"
import "../static/style/pageStyle/benefits.scss"

import benfitImg from "../static/images/benefits.webp"
import sideBar from "../static/images/sidebar.webp"
import DownloadBtnBlack from "../components/DownloadBtn-black"
import { Helmet } from "react-helmet"

const Benefits = props => {
  const title = "Benefits | Pryzm - A Data Reliability & Observability Platform";

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>{title}</title>
      </Helmet>
      <div className={`platform-v3 benefits`}>
        <div className="page-content">
          <div className="page-wrapper">
            <div className="banner">
              <BannerHeader header="How Can Enterprises<br /> Benefit from Pryzm?" />
            </div>
            <div className="frame">
              <div className="main-frame">
                <div className="main-image">
                  <img src={benfitImg} alt="benefit-image" />
                </div>
              </div>
            </div>
            <div className="btn-area">
              <DownloadBtnBlack
                text="Download Pryzm Data Sheet"
                pdf={"PRYZM_Datasheet.pdf"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Benefits
